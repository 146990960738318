import {React, useState} from "react";
import Button from "./button";
import touchIcon from "../static/touchIcon.svg";

import "./gameSpeech.scss";

const GameSpeech = (props) => {
    const icon = props.icon;
    let iconClass = "game-speech-icon";
    const speaker = props.speaker;
    const speech = props.speech;
    const handleClick = props.handleClick;
    const [show, setShow] = useState(true);

    if (icon === "None"){
        iconClass = "hidden"; 
    }

    // const className = (show) ? "game-speech" : "hidden";

    // const handleClick = () => {
    //     setShow(false);
    // }

    return (
        <div className = "game-speech-container" onClick = {handleClick}>
            <div className = "game-speech">
            {/* <div className = "speech-bubble">
                <div className = "speaker">{speaker}</div>
                <div className = "speech">{'"' + speech + '"'}</div>
            </div> */}
                <div className = "text-container">
                    <div className = "speaker-box">{speaker}</div>
                    <div className = "speech-row">
                        <div className = "speech-box">{speech}</div>
                        <img className = "touch-icon" src = {touchIcon} alt = "touch icon"/>
                    </div>
                </div>
                <img className = {iconClass} src = {icon} alt = "suspicious man"/>
            </div>
        </div>
    )


}

export default GameSpeech;