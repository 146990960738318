import React from "react";

import "./mainChoice.scss";

const MainChoice = (props) => {
    const choices = props.choices;
    const icon = props.icon;
    const text = props.text;

    return (
        <div className="main-choice-container">
            <div className = "main-choices">
                <img className="main-choice-icon" src={icon} alt="choice icon" draggable = "false"/>
                <div className = "choices-box">
                    <div className = "choices-header">{text}</div>
                    <div className = "choice-buttons-container">
                        {choices.map((choice, index) => 
                            <div className="choice-button" key={index} onClick={() => props.jumpIndex(choice.jumpIndex)}>{choice.text}</div>
                        )}
                    </div>
                </div>
            </div>  
        </div>
    );
};

export default MainChoice;