import React from 'react';
import { useNavigate } from 'react-router-dom';
import './introSlide5.scss';
import backArrow from '../static/back.svg';
import holdingHand from '../static/holdinghand.png'

const IntroSlide5 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/intro-slide6');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/intro-slide4');
    };

    return (
        <div className="slide5-container" onClick={handleNextClick}>
            <div className="overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="back-arrow" onClick={handleBackClick}/>
            <div className="content">
                <div className="text-content">
                    <p className="subheader">DID YOU KNOW?</p>
                    <div className="info-box">
                        <h1 className="header1">1 IN 3 WOMEN</h1>
                        <p className="subheader">have experienced sexual violence in their lifetime.</p>
                    </div>
                </div>
                <img src={holdingHand} alt="Holding Hand" className="holding-hand-icon" />
            </div>
            <div className="dots">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 5 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default IntroSlide5;
