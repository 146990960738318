import logo from './logo.svg';
import Button from './components/button';
import Landing from './pages/landing';
import Interactive from './pages/interactive';
import SplashScreen from './intro-slides/splashScreen';
import IntroSlide1 from './intro-slides/introSlide1';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import IntroSlide2 from './intro-slides/introSlide2';
import IntroSlide3 from './intro-slides/introSlide3';
import IntroSlide4 from './intro-slides/introSlide4';
import IntroSlide5 from './intro-slides/introSlide5';
import IntroSlide6 from './intro-slides/introSlide6';
import IntroSlide7 from './intro-slides/introSlide7';
import IntroSlide8 from './intro-slides/introSlide8';
import EndSlide1 from './conclusion-slides/endSlide1';
import EndSlide2 from './conclusion-slides/endSlide2';
import EndSlide3 from './conclusion-slides/endSlide3';
import EndSlide4 from './conclusion-slides/endSlide4';
import EndSlide5 from './conclusion-slides/endSlide5';
import EndSlide6 from './conclusion-slides/endSlide6';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="/interactive" element={<Interactive />} />
        <Route path="/" element={<SplashScreen/>}/>
        <Route path = "/intro-slide1" element={<IntroSlide1/>}/>
        <Route path = "/intro-slide2" element={<IntroSlide2/>}/>
        <Route path = "/intro-slide3" element={<IntroSlide3/>}/>
        <Route path = "/intro-slide4" element={<IntroSlide4/>}/>
        <Route path = "/intro-slide5" element={<IntroSlide5/>}/>
        <Route path = "/intro-slide6" element={<IntroSlide6/>}/>
        <Route path = "/intro-slide7" element={<IntroSlide7/>}/>
        <Route path = "/intro-slide8" element={<IntroSlide8/>}/>
        <Route path = "/conclusion-slide1" element={<EndSlide1/>}/>
        <Route path = "/conclusion-slide2" element={<EndSlide2/>}/>
        <Route path = "/conclusion-slide3" element={<EndSlide3/>}/>
        <Route path = "/conclusion-slide4" element={<EndSlide4/>}/>
        <Route path = "/conclusion-slide5" element={<EndSlide5/>}/>
        <Route path = "/conclusion-slide6" element={<EndSlide6/>}/>
      </Routes>
    </BrowserRouter>  
  )
}

export default App;
