import React from 'react';
import { useNavigate } from 'react-router-dom';
import './endSlide4.scss';
import backArrow from '../static/back.svg';

const EndSlide4 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/conclusion-slide5');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/conclusion-slide3');
    };

    return (
        <div className="endslide4-container" onClick={handleNextClick}>
            <div className="endslide4-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="endslide4-back-arrow" onClick={handleBackClick}/>
            <div className="endslide4-content-box">
                <div className="endslide4-content">
                    <p className="slide-number">3.</p>
                    <h1 className="header1">Focus on de-escalation.</h1>
                    <p className="endslide4-body-text">Refrain from behaviors that could provoke or aggravate the other person, such as taunting, insulting, or challenging them.</p>
                    <p className="endslide4-body-text">Instead, focus on de-escalating the situation and finding a peaceful resolution.</p>
                </div>
            </div>
            <div className="dots">
                {[...Array(3)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 3 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}
    
export default EndSlide4;