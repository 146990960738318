import React from 'react';
import { useNavigate } from 'react-router-dom';
import './introSlide4.scss';
import backArrow from '../static/back.svg';

const IntroSlide4 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/intro-slide5');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/intro-slide3');
    };

    return (
        <div className="slide4-container" onClick={handleNextClick}>
            <div className="slide4-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="back-arrow" onClick={handleBackClick}/>
            <div className="slide4-content">
                <div className="enough-text">
                    <h1 className="header1">
                        ENOUGH IS <span className="highlight">ENOUGH.</span>
                    </h1>
                </div>
            </div>
            <div className="dots">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 4 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default IntroSlide4;
