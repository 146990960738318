import {React, useState} from "react";
import GameSpeech from "./gameSpeech";
import GameContext from "./gameContext";
import MainChoice from "./mainChoice";
import SecondChoice from "./secondChoice";
import Tip from "./tip";
import Outro from "./outro";
import susMan from "../static/susMan.png";
import choiceIcon from "../static/choiceIcon.svg";

const flow = [
    {
        type: "speech",
        icon: susMan,
        speaker: "STRANGER",
        speech: "Hey, 'scuse me! I need your help. Could you lend a hand?"
    },
    {
        type: "context",
        text: "You realize you are alone and the stranger is approaching you. Your intuition bells go off."
    },
    {
        type: "main-choice",
        icon: choiceIcon,
        text: "WHAT WILL YOU DO?",
        choices: [
            {
                text: "AVOID",
                jumpIndex: 3
            },
            {
                text: "CONFRONT",
                jumpIndex: 4
            }
        ]
    }, 
    {
        type: "second-choice",
        prev: "AVOID",
        text: "WHAT IS YOUR NEXT STEP?",
        choices: [
            {
                text: "Speedwalk to your apartment which is 2 blocks away.",
                jumpIndex: 5
            },
            {
                text: "Walk into the convenience store nearby where there are security cameras.",
                jumpIndex: 6
            },
            {
                text: "Find a group of people nearby you don’t know and ask them for help.",
                jumpIndex: 7
            }
        ]
    },
    {
        type: "second-choice", 
        prev: "CONFRONT",
        text: "WHAT IS YOUR NEXT STEP?",
        choices: [
            {
                text: "Stand your ground, maintaining a safe distance and firm posture.",
                jumpIndex: 8
            },
            {
                text: "Cause a scene so people nearby can hear.",
                jumpIndex: 10
            },
            {
                text: "Get prepared to defend yourself physically.",
                jumpIndex: 12
            }
        ]
    }, 
    {
        type: "tip",
        prevNumber: 1,
        prevText: "Speedwalk to your apartment which is 2 blocks away.",
        text: "Speed-walking to your apartment is a good option, but make sure to vary your pace and keep an eye on your surroundings.",
        tipText: "Calling a friend and sharing your location is an excellent safety measure. Always let someone know where you are and when you expect to arrive home.",
        jumpTo: 3
    },
    {
        type: "tip",
        prevNumber: 2,
        prevText: "Walk into the convenience store nearby where there are security cameras.",
        text: "Walking into the convenience store is a smart move as it provides safety in numbers with the presence of the cashier and other customers.",
        tipText: "Calling a friend and sharing your location is an excellent safety measure. Always let someone know where you are and when you expect to arrive home.",
        jumpTo: 3
    },
    {
        type: "tip",
        prevNumber: 3,
        prevText: "Find a group of people nearby you don’t know and ask them for help.",
        text: "Seeking assistance from a group of strangers is a proactive way to enhance safety, as there is strength in numbers and it can deter potential threats.",
        tipText: "Approach the group calmly, explain your situation respectfully, and express gratitude.",
        jumpTo: 3
    },
    {
        type: "speech", 
        icon: "None", 
        speaker: "YOU",
        speech: "I don't know you. Leave me alone!"
    },
    {
        type: "tip", 
        prevNumber: 1,
        prevText: "Stand your ground, maintaining a safe distance and firm posture.",
        text: "Standing your ground with a firm posture is okay, but try to avoid escalating the situation.",
        tipText: "Be sure to pay attention to anyone around you that you can reach out for help! Have situational awareness at all times!",
        jumpTo: 4
    },
    {
        type: "speech", 
        icon: "None", 
        speaker: "YOU",
        speech: "“I don’t know this man! I need help! (yelling)"
    },
    {
        type: "tip", 
        prevNumber: 2,
        prevText: "Cause a scene so people nearby can hear.",
        text: "Causing a scene might attract attention, but it could also escalate things. Use this option cautiously and only if you feel it's necessary for your safety.",
        tipText: "Have awareness of your environment and the aggressor. Pretending to have someone on call (or calling someone) can also give the illusion that someone is waiting for you and makes you a less viable target.",
        jumpTo: 4
    }, 
    {
        type: "tip", 
        prevNumber: 3,
        prevText: "Get prepared to defend yourself physically.",
        text: "Getting prepared to defend yourself physically should be a last resort. It's better to try to de-escalate or escape the situation if possible.",
        tipText: "Carry items like pepper spray or a personal alarm in your bag or pocket. These tools can provide you with a sense of security and can be used to deter potential threats.",
        jumpTo: 4
    },
    {
        type: "outro"
    }
    // {
    //     type: "urmum",
    //     icon: susMan,
    //     speaker: "SUSPECT",
    //     speech: "i HAVE A BURGLARY",
    // }
]

const GameScene = () => {
    const [index, setIndex] = useState(0);

    const handleClick = () => {
        if (index < flow.length - 1) {
            setIndex(index + 1);
        }
    }

    const jumpIndex = (newIndex) => {
        if (newIndex < flow.length) {
            setIndex(newIndex);
        }
    }

    const prevIndex = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    }

    const renderComponent = (item) => {
        switch (item.type) {
            case 'speech':
                return <GameSpeech key={index} icon={item.icon} speaker={item.speaker} speech={item.speech} handleClick = {handleClick}/>;
            case 'context':
                console.log(item.text);
                return <GameContext key={index} text={item.text} handleClick = {handleClick}/>;
            case 'main-choice':
                return <MainChoice key={index} icon={item.icon} text={item.text} choices={item.choices} jumpIndex = {jumpIndex}/>;
            case 'second-choice': 
                // console.log(item.choices);
                return <SecondChoice key={index} prev={item.prev} text = {item.text} jumpIndex = {jumpIndex} choices = {item.choices}/>;
            case 'tip':
                return <Tip key={index} prevNumber = {item.prevNumber} prevText = {item.prevText} text = {item.text} tipText = {item.tipText} jumpIndex = {jumpIndex} jumpTo = {item.jumpTo} nextIndex = {handleClick}/>;
            case 'outro':
                return <Outro key={index} jumpIndex = {jumpIndex}/>;
            default:
                return <div key={index}>Unknown Type</div>;
        }
    };

    return (
        <div>
            {renderComponent(flow[index])}
        </div>
    )
}

export default GameScene;