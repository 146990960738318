import {React, useState} from "react";
import Button from "./button";
import "./overlay.scss";

const Overlay = () => {
    const content = [
        {
            title: "HOW TO NAVIGATE",
            subtitle: "Turn your head to examine the environment around you.",
            buttonText: "Next",
            showIcon: true
        },
        {
            title: "TAKE A LOOK AROUND.",
            subtitle: "Drag to explore the environment.",
            buttonText: "Enter Scene",
            showIcon: false
        }
    ]

    const [index, setIndex] = useState(0);
    const showOverlay = (index < content.length) ? "overlay-container" : "hide"; 

    const handleClick = () => {
        setIndex(index + 1);
    }

    return (
        <div className= {showOverlay} >
            <div className = "overlay-box">
                <div className = "overlay-title">
                    {(index < content.length) ? content[index].title : ""}
                </div>
                <div className = "overlay-subtitle">
                    {(index < content.length) ? content[index].subtitle : ""}
                </div>
                <Button text = {(index < content.length) ? content[index].buttonText : ""} showIcon = {(index < content.length) ? content[index].showIcon : false} handleClick = {handleClick}/>
            </div>
        </div>
    )
}

export default Overlay;