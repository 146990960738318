import React from 'react';
import { useNavigate } from 'react-router-dom';
import './endSlide1.scss';
import HandClick from '../static/handclick.png';

const EndSlide1 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/conclusion-slide2');
    };

    
    return (
        <div className="endslide1-container" onClick={handleNextClick}>
            <div className="endslide1-overlay"></div>
            <div className="endslide1-content">
                <p className="endslide1-subheader">OUR GOAL</p>
                <div className="endslide1-header1-box">
                    <h1 className="header1">
                    We hope for <span className="highlight">everyone</span> to feel safe and comfortable, anytime and anywhere, regardless of age, gender, race, or ability. 
                    </h1>
                </div>
            </div>
            <div className="takeaways">
                <img src={HandClick} alt="Hand click" className="handclick-icon" />
                <span>Click for takeaways</span>
            </div>
        </div>
    );
}
    
export default EndSlide1;