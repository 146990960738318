import React from "react";
import "./button.scss";
import arrow from "../static/arrow.svg";

const Button = (props) => {
    const text = (props.text !== undefined) ? props.text : "";
    const textClass = (text === "") ? "hide" : "button-text";

    const handleClick = () => {
        if (props.handleClick !== undefined) {
            props.handleClick();
        }
    }

    const showIcon = (props.showIcon !== undefined) ? props.showIcon : false;
    const iconClass = (showIcon) ? "button-icon" : "hide";

    return (
        <div className = "button-container" onClick = {handleClick}>
            <div className = {textClass}>
                {text}
            </div>
            <div className = {iconClass}>
                <img src = {arrow} alt = "arrow"/>
            </div>
        </div>
    )
}

export default Button;