import React from 'react';
import { useNavigate } from 'react-router-dom';
import './introSlide7.scss';
import backArrow from '../static/back.svg';

const IntroSlide7 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/intro-slide8');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/intro-slide6');
    };

    return (
        <div className="slide7-container" onClick={handleNextClick}>
            <div className="slide7-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="slide7-back-arrow" onClick={handleBackClick} />
            <div className="slide7-content">
                <p className="slide7-subheader">BEFORE WE BEGIN</p>
                <div className="slide7-header1-box">
                    <h1 className="header1">
                    Keep in mind that the goal of self-defense is <span className="highlight">de-escalation.</span>
                    </h1>
                </div>
                <p className="body-text">Whether walking home late at night or navigating your everyday life, we aim to equip you with the verbal and physical skills to defend yourself from threats.</p>
            </div>
            <div className="dots">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 7 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}
    
export default IntroSlide7;
