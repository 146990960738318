import React from 'react';
import { useNavigate } from 'react-router-dom';
import './introSlide6.scss';
import backArrow from '../static/back.svg';

const IntroSlide6 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/intro-slide7');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/intro-slide5');
    };

    return (
        <div className="slide6-container" onClick={handleNextClick}>
            <div className="slide6-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="slide6-back-arrow" onClick={handleBackClick}/>
            <div className="slide6-content">
                <div className="slide6-header1-box">
                    <h1 className="header1">
                        Let's walk through a module with a <span className="highlight">real-life</span> situation.
                    </h1>
                </div>
                <p className="body-text">Try to imagine what you would do if you ever found yourself in these situations.</p>
            </div>
            <div className="dots">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 6 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default IntroSlide6;
