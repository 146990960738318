import React from 'react';
import { useNavigate } from 'react-router-dom';
import './endSlide5.scss';
import HandClick from '../static/handclick.png';
import backArrow from '../static/back.svg';

const EndSlide5 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/conclusion-slide6');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/conclusion-slide4');
    };

    return (
        <div className="endslide5-container" onClick={handleNextClick}>
            <div className="endslide5-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="endslide5-back-arrow" onClick={handleBackClick}/>
            <div className="endslide5-content">
                
                <div className="endslide5-header1-box">
                    <h1 className="header1">
                    Thank you for engaging in this interactive experience!
                    </h1>
                </div>
                <p className="endslide5-body-text">This immersive experience was made by IMPACT Personal Safety in collaboration with Nova, Tech for Good, a team of UCLA students committed to creating high-impact technology for nonprofits.</p>
            </div>
            <div className="takeaways">
                <img src={HandClick} alt="Hand click" className="handclick-icon" />
                <span>Resources</span>
            </div>
        </div>
    );
}
    
export default EndSlide5;