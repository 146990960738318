import React from 'react';
import { useNavigate } from 'react-router-dom';
import './introSlide8.scss';
import backArrow from '../static/back.svg';
import arrowImage from '../static/rightarrow.png';

const IntroSlide8 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/interactive');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/intro-slide7');
    };

    return (
        <div className="slide8-container" onClick={handleNextClick}>
            <div className="slide8-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="slide8-back-arrow" onClick={handleBackClick}/>
            <div className="slide8-content">
                <p className="slide8-subheader">&nbsp;</p>
                <div className="slide8-header1-box">
                    <h1 className="header1">
                    Keep in mind that the goal of self-defense is <span className="highlight">de-escalation.</span>
                    </h1>
                </div>
                <p className="slide8-body-text">Whether walking home late at night or navigating your everyday life, we aim to equip you with the verbal and physical skills to defend yourself from threats.</p>
            </div>
            <div className="dots">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 7 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
            <div className="begin-experience">
                <span>Begin experience</span>
                <img src={arrowImage} alt="Arrow" className="arrow-icon" />
            </div>
        </div>
    );
}
    
export default IntroSlide8;