import React from 'react';
import { useNavigate } from 'react-router-dom';
import './endSlide2.scss';
import HandClick from '../static/handclick.png';

const EndSlide2 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/conclusion-slide3');
    };

    return (
        <div className="endslide2-container" onClick={handleNextClick}>
            <div className="endslide2-overlay"></div>
            <div className="endslide2-content-box">
                <div className="endslide2-content">
                    <p className="slide-number">1.</p>
                    <h1 className="header1">Stay aware of your surroundings.</h1>
                    <p className="endslide2-body-text">Always be mindful of your surroundings, especially when walking alone at night.</p>
                    <p className="endslide2-body-text">Trust your instincts and if something feels off, take action to ensure your safety.</p>
                </div>
            </div>
            <div className="dots">
                {[...Array(3)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 1 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}
    
export default EndSlide2;