import {React, useState} from "react";
import Button from "./button";
// import GameSpeech from "./gameSpeech";
import GameScene from "./gameScene";
import "./gameOverlay.scss";

const GameOverlay = (props) => {
    const distance = props.distance;

    const className = (distance >= 750) ? "game-overlay" : "hidden";

    return (
        <div className = {className}>
            <GameScene/>
        </div>
    )
}

export default GameOverlay;