import React from 'react';
import './endSlide6.scss';
import ArrowImage from '../static/uprightarrow.png';

const EndSlide6 = () => {

    return (
        <div className="endslide6-container">
            <div className="endslide6-overlay"></div>
            <div className="endslide6-content">
                <h1 className="endslide6-header1">
                    Take control of your life with <span className="highlight" onClick = {() => {window.location.href = "https://www.impactpersonalsafety.com/"}}>IMPACT <img src={ArrowImage} alt="Arrow" className="arrow-icon" /></span>
                </h1>
                <p className="endslide6-subheader">OUR CLASSES</p>
                <div className="class-boxes">
                    <div className="class-box">
                        <h2 className="class-title">In-Person Basics Class</h2>
                        <p className="class-description">
                            In our flagship 16-hour course, you'll learn verbal and physical defense tactics for dealing with strangers and people you know.
                        </p>
                    </div>
                    <div className="class-box">
                        <h2 className="class-title">All-Gender Intro Workshop</h2>
                        <p className="class-description">
                            This three-hour class gives you a taste of all our training. Work on awareness and self-defense skills in a safe environment.
                        </p>
                    </div>
                    <div className="class-box">
                        <h2 className="class-title">Defense Against An Armed Assailant</h2>
                        <p className="class-description">
                            We demystify weapon hype and explore why assailants choose what they do. Gain confidence in handling threats smartly.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EndSlide6;
