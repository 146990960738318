import React from "react";
import tipIcon from "../static/tip.svg";
import anotherButton from "../static/another.svg";
import nextButton from "../static/next.svg";

import "./tip.scss";

const Tip = (props) => {
    const prevNumber = props.prevNumber; 
    const prevText = props.prevText; 
    const text = props.text;
    const tipText = props.tipText;
    const jumpIndex = props.jumpIndex;
    const jumpTo = props.jumpTo;
    const nextIndex = props.nextIndex;


    return (
        <div className = "tip-container">
            <div className = "tip-content-container">
                <div className = "prev-label">You Chose</div>
                <div className = "prev-box">
                    <div className = "prev-number-container">
                        <div className = "prev-number">{`OPTION ${prevNumber}`}</div>
                    </div>
                    <div className = "prev-text">{prevText}</div>
                </div>
                <div className = "tip-box">
                    <div className = "tip-text">{text}</div>
                    <div className = "tip-bottom-container">
                        <img className = "tip-icon" src = {tipIcon} alt = "tip icon"/>
                        <div className = "tip-suggest">{tipText}</div>
                    </div>
                </div>
            </div>
            <div className = "bottom-container">
                <img className = "bottom-button" src = {anotherButton} alt = "another button" draggable = "false" onClick = {() => jumpIndex(jumpTo)}/>
                <img className = "bottom-button" src = {nextButton} alt = "next button" draggable = "false" onClick = {() => jumpIndex(13)}/>
            </div>
        </div>
    )
}

export default Tip;