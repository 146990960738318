import React from 'react';
import { useNavigate } from 'react-router-dom';
import './splashScreen.scss';

const SplashScreen = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/intro-slide1');
    };
    return (
        <div className="splash-container" onClick={handleNextClick}>
            <div className="overlay"></div>
            <div className="content">
                <p className="subheader">WELCOME TO</p>
                <div className="header-box">
                    <h1 className="header1">IMPACT</h1>
                    <div className="subheader-box">
                        <p className="subheader">PERSONAL</p>
                        <p className="subheader">SAFETY</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SplashScreen;
