import React from 'react';
import { useNavigate } from 'react-router-dom';
import './introSlide1.scss';
import manIcon from '../static/vrmanwithhand.png';

const IntroSlide1 = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/intro-slide2');
    };

    return (
        <div className="slide1-container" onClick={handleClick}>
            <div className="slide1-overlay"></div>
            <div className="slide1-content">
                <p className="subheader">WHAT THIS IS</p>
                <div className="header1-box">
                    <h1 className="header1">
                        An immersive digital experience
                    </h1>
                    <img src={manIcon} alt="Man Icon" className="man-icon" />
                </div>
                <div className="description">
                    <div className="description-item">
                        <div className="number-box">1</div>
                        <p className="body-text">to teach you the importance of self-defense.</p>
                    </div>
                    <div className="description-item">
                        <div className="number-box">2</div>
                        <p className="body-text">to empower you in real-life scenarios.</p>
                    </div>
                </div>
            </div>
            <div className="dots">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index === 0 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default IntroSlide1;
