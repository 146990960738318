import React from 'react';
import { useNavigate } from 'react-router-dom';
import './endSlide3.scss';
import backArrow from '../static/back.svg';

const EndSlide3 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/conclusion-slide4');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/conclusion-slide2');
    };

    return (
        <div className="endslide3-container" onClick={handleNextClick}>
            <div className="endslide3-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="endslide3-back-arrow" onClick={handleBackClick}/>
            <div className="endslide3-content-box">
                <div className="endslide3-content">
                    <p className="slide-number">2.</p>
                    <h1 className="header1">Keep calm & stay composed.</h1>
                    <p className="endslide3-body-text">In tense or potentially confrontational situations, try to remain calm and composed.</p>
                    <p className="endslide3-body-text">Avoid raising your voice or making sudden movements that could escalate the situation.</p>
                </div>
            </div>
            <div className="dots">
                {[...Array(3)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 2 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}
    
export default EndSlide3;