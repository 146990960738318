import React from 'react';
import { useNavigate } from 'react-router-dom';
import './introSlide3.scss';
import backArrow from '../static/back.svg';
import fistHandIcon from '../static/punch.png';

const IntroSlide3 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/intro-slide4');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/intro-slide2');
    };

    return (
        <div className="slide3-container" onClick={handleNextClick}>
            <div className="slide3-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="back-arrow" onClick={handleBackClick}/>
            <div className="content">
                <div className="main-content">
                    <img src={fistHandIcon} alt="Fist Hitting Hand" className="fist-hand-icon" />
                    <div className="text-content">
                        <h1 className="header1">
                            Your life is <span className="highlight">worth</span> fighting for.
                        </h1>
                    </div>
                </div>
            </div>
            <div className="dots">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 3 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default IntroSlide3;
