import React from "react";

import backArrow from "../static/back.svg";
import "./secondChoice.scss";

const SecondChoice = (props) => {
    const prev = props.prev;
    const text = props.text;
    const jumpIndex = props.jumpIndex;
    const choices = props.choices;

    console.log(choices);
    return (
        <div className = "second-choice-container">
            <div className = "second-choice-content">
                <div className = "prev-choice">You chose to <span className = "prev">{prev}</span></div>
                <div className = "choices-box">
                    <div className = "choices-header">{text}</div>
                    <div className = "choices-container">
                        {choices.map((choice, index) => 
                            {return <Option index = {index} jumpTo = {choice.jumpIndex} text = {choice.text} jumpIndex = {jumpIndex}/>}
                        )}
                    </div>
                </div>
            </div>
            <div className = "back-arrow-container">
                <img className = "back-arrow" src = {backArrow} alt = "back arrow" draggable = "false" onClick = {() => jumpIndex(2)}/>
            </div>
        </div>
    );
}

const Option = (props) => {
    const index = props.index;
    const jumpTo = props.jumpTo;
    const text = props.text;
    const jumpIndex = props.jumpIndex;

    return (
        <div className = "option" onClick = {() => jumpIndex(jumpTo)}>
            <div className = "option-label">{`OPTION ${index + 1}`}</div>
            <div className = "option-text">{text}</div>
        </div>
    )
}

export default SecondChoice;