import React from "react";

import "./gameContext.scss";

const GameContext = (props) => {
    const text = props.text;
    const handleClick = props.handleClick;

    return (
        <div className = "game-context-container" onClick = {handleClick}>
            <div className="gameContext">
                <div className = "context-text">
                    {props.text}
                </div>
            </div>
        </div>
    );
};  

export default GameContext;