import {React, useState} from "react";
import Overlay from '../components/overlay';
import GameOverlay from '../components/gameOverlay';
import Spline from '@splinetool/react-spline';
import './interactive.scss';

const Interactive = () => {

    //Tracks mouse drag distance to determine how much a user has "looked around"
    const [mouseHeld, setMouseHeld] = useState(false);
    const [totalDistance, setTotalDistance] = useState(0);
    const [prevX, setPrevX] = useState(null);
    const [prevY, setPrevY] = useState(null);

    const mouseDown = () => {
        setMouseHeld(true);
    }

    const mouseUp = () => {
        setMouseHeld(false);
    }

    const handleMouseMove = (e) => {
        if (mouseHeld && prevX !== null && prevY !== null) {
            const deltaX = Math.abs(e.clientX - prevX);
            const deltaY = Math.abs(e.clientY - prevY);
            const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
            console.log(totalDistance + distance);
            setTotalDistance(totalDistance + distance);
        }
        setPrevX(e.clientX);
        setPrevY(e.clientY);
    }

    return (
        <div className = "interactive-page">
            <Overlay />
            <GameOverlay distance = {totalDistance} />
            <div className = "spline-container" onMouseDown = {mouseDown} onMouseUp = {mouseUp} onMouseMove = {handleMouseMove}>
                <Spline scene = "https://prod.spline.design/4zSwlfuUWa1IY4Jw/scene.splinecode"/>
            </div>
        </div>
    )
}

export default Interactive;