import React from "react";

import Button from "./button";

import "./outro.scss";

const Outro = (props) => {

    return (
        <div className = "outro-container">
            <div className = "outro-box">
                <div className = "outro-header">
                    SUCCESS.
                </div>
                <div className = "outro-text">
                    Through your choices, you were successfully able to reach a position of safety.
                </div>
                <div className = "button-row">
                    <div className = "again-button" onClick = {() => props.jumpIndex(2)}>TRY AGAIN</div>
                    <div className = "again-button" onClick = {() => window.location.href = "/conclusion-slide1"}>FINISH</div>
                    {/* <Button text = "TRY AGAIN" handleClick = {() => props.jumpIndex(2)}/>
                    <Button text = "FINISH" handleClick = {() => window.location.href = "/"}/> */}
                </div>
            </div>
        </div>
    )
};

export default Outro;