import React from 'react';
import { useNavigate } from 'react-router-dom';
import './introSlide2.scss';
import backArrow from '../static/back.svg';

const IntroSlide2 = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/intro-slide3');
    };

    const handleBackClick = (event) => {
        event.stopPropagation();
        navigate('/intro-slide1');
    };

    return (
        <div className="slide2-container" onClick={handleNextClick}>
            <div className="slide2-overlay"></div>
            <img src={backArrow} alt="Back Arrow" className="slide2-back-arrow" onClick={handleBackClick}/>
            <div className="slide2-content">
                <p className="subheader">WHO WE ARE</p>
                <div className="header1-box">
                    <h1 className="header1">
                        We are dedicated to ending the cycle of violence by <span className="highlight">empowering people</span> to take control of their lives.
                    </h1>
                </div>
            </div>
            <div className="dots">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index < 2 ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default IntroSlide2;
